import clsx from "clsx";
import styles from "./ImageCarousel.module.css";

interface ImageCarouselImage {
  src: string;
  alt: string;
}

export interface ImageCarouselProps {
  imageUrls: ImageCarouselImage[];
  height: string;
  animationDuration?: string;
}

export default function ImageCarousel({
  imageUrls,
  height,
  animationDuration = "10s",
}: ImageCarouselProps) {
  return (
    <div className={clsx(styles["carousel-container"], "overflow-hidden w-full", height)}>
      <div className={clsx(styles["carousel-track"], "flex")} style={{ animationDuration }}>
        {[...imageUrls, ...imageUrls].map(({ src, alt }, i) => (
          <picture key={i} className="mx-4">
            <img src={src} alt={alt} className={clsx("max-w-none", height)} />
          </picture>
        ))}
      </div>
    </div>
  );
}
