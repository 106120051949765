import Head from "next/head";

export default function Layout({ children, title }: React.ComponentProps<"div">) {
  const titleText = `${title} - Treater`;
  return (
    <>
      <Head>
        <title>{titleText}</title>
      </Head>
      {children}
    </>
  );
}
